<template>
  <v-app-bar absolute elevation="0" color="transparent" class="px-7 nav-pd">
    <Logo />
    <v-spacer></v-spacer>
    <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="auto" class="test_orange">
      <scrollactive
        ref="scrollactive"
        click-to-scroll
        :offset="options.offset"
        :duration="options.duration"
        bezier-easing-value=".5,0,.35,1"
        @itemchanged="onItemChanged"
      >
        <v-tabs
          v-model="tab"
          centered
          class="tabsBackground"
          slider-color="transparent"
        >
          <v-tab
            v-for="(item, index) in sections"
            :key="index"
            class="px-10 scrollactive-item"
            :href="'#' + item.id"
            :target="item.id"
          >
            <span class="font_tabs" v-text="$t(item.title)" />
          </v-tab>
        </v-tabs>
      </scrollactive>
    </v-col>
    <Language />
    <!--    <ButtonContact />-->
  </v-app-bar>
</template>

<script>
export default {
  name: "MenuHeaderPublic",
  props: {
    sections: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    tab: null,
    options: {
      duration: 1500,
      offset: 0,
      easing: "easeInOutCubic",
    },
  }),
  computed: {
    activeTab() {
      return "link-active";
    },
    width: function () {
      switch (true) {
        case this.$vuetify.breakpoint.xsOnly:
          return "100%";
        case this.$vuetify.breakpoint.smOnly:
          return "60%";
        case this.$vuetify.breakpoint.mdOnly:
          return "50%";
        case this.$vuetify.breakpoint.lgAndUp:
          return "30%";
        default:
          return "100%";
      }
    },
  },
  methods: {
    logout: async function () {
      this.$store.dispatch("loading/start");
      await this.$store.dispatch("auth/logout");
      this.$router.push("/");
      this.$store.dispatch("loading/done");
    },

    onItemChanged: function (event, currentItem) {
      if (currentItem) {
        this.tab = currentItem.target;
      }
    },
  },
};
</script>
<style>
.tabsBackground .v-item-group.v-slide-group.v-tabs-bar {
  background-color: transparent;
}
</style>
<style lang="scss" scoped>
.font_tabs {
  font-family: $font_Regular;
  font-size: 1rem;
  color: #ffffff;
}
.nav-pd {
  padding: 0 10vw !important;
  z-index: 5000 !important;
}

.link-active .font_tabs {
  color: var(--v-primary-base) !important;
}

.v-tab {
  text-transform: none !important;
}

.v-tab:hover {
  opacity: 0.7;
}

//IPAD VERTICAL
@media only screen and (min-width: 768px) and (max-width: 999px) {
  .v-list-item .v-list-item__subtitle,
  .v-list-item .v-list-item__title {
    line-height: 2.2 !important;
  }
  .v-list-item__title {
    align-self: center;
    font-size: 1.7rem !important;
  }
  .nav-pd {
    padding: 0 3vw !important;
  }
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .nav-pd {
    padding: 0 4vw !important;
  }
}

//IPAD LANDSCAPE
@media only screen and (min-width: 1000px) and (max-width: 1169px) and (orientation: landscape) {
  .v-list-item .v-list-item__subtitle,
  .v-list-item .v-list-item__title {
    line-height: 2.5 !important;
  }
  .v-list-item__title {
    align-self: center;
    font-size: 1.5rem !important;
  }
}
</style>
