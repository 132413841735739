<template>
  <v-menu v-if="user" bottom min-width="200px" rounded offset-y>
    <template v-slot:activator="{ on }">
      <v-btn icon x-large v-on="on">
        <v-tooltip bottom color="#000">
          <template v-slot:activator="{ on2, attrs2 }">
            <v-avatar color="primary" size="35" v-bind="attrs2" v-on="on2">
              <span class="white--text text-h7">{{ initials }}</span>
            </v-avatar>
          </template>
          <span v-text="'Profile'" />
        </v-tooltip>
      </v-btn>
    </template>
    <v-card>
      <v-list-item-content class="justify-center">
        <div class="mx-auto text-center">
          <v-avatar color="primary">
            <span class="white--text text-h5">{{ initials }}</span>
          </v-avatar>
          <h3>{{ user.fullName }}</h3>
          <p class="text-caption mt-1">
            {{ user.userName }}
          </p>
          <v-divider class="my-3"></v-divider>
          <v-btn depressed block text :to="{ name: 'Companies' }">
            {{ $t("general.manage_account") }}
          </v-btn>
          <v-divider class="my-3"></v-divider>
          <v-btn depressed block text @click.native="logout">
            {{ $t("general.logout") }}
          </v-btn>
        </div>
      </v-list-item-content>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Profile",
  computed: {
    ...mapGetters(["user"]),
    initials() {
      if (this.user) {
        return (
          this.user.firstName.slice(0, 1).toUpperCase() +
          this.user.lastName.slice(0, 1).toUpperCase()
        );
      } else {
        return "";
      }
    },
  },
  methods: {
    async logout() {
      this.$store.dispatch("loading/start");
      this.$router.push({ name: "Home" });
      await this.$store.dispatch("auth/logout");
      await this.$store.dispatch("storeInfo/delete");
      this.$store.dispatch("loading/done");
    },
  },
};
</script>
