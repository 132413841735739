<template>
  <span :class="headerClass" :style="{ color: textColor, textAlign: align }">{{
    text
  }}</span>
</template>

<script>
export default {
  name: "TextBlock",
  props: {
    level: {
      type: String,
      required: true,
    },
    textColor: {
      type: String,
      default: "#131313",
    },
    align: {
      type: String,
      default: "center",
    },
    text: {
      type: String,
      default: "Default Header Text",
    },
  },
  computed: {
    headerClass() {
      return `text-level-${this.level}`;
    },
  },
};
</script>

<style scoped>
.text-level-1 {
  font-size: 3rem;
  font-family: "SourceSansPro-Regular", sans-serif;
  font-weight: 500;
  line-height: 62px !important;
  text-align: center;
}
.text-level-2 {
  font-size: 2.5rem;
  font-family: "SourceSansPro-Regular", sans-serif;
  font-weight: 500;
  line-height: 52px !important;
  text-align: center;
}
.text-level-3 {
  font-size: 1.25rem;
  font-family: "SourceSansPro-Regular", sans-serif;
  line-height: 30px !important;
  text-align: center;
}
.text-level-5 {
  font-size: 0.875rem;
  font-family: "SourceSansPro-Regular", sans-serif;
  line-height: 20px !important;
}
@media screen and (max-width: 768px) {
  .text-level-1 {
    font-size: 2.5rem;
    line-height: 52px !important;
  }

  .text-level-2 {
    font-size: 2rem;
    line-height: 40px !important;
  }

  .text-level-3 {
    font-size: 1.2rem;
    line-height: 25px !important;
  }
}

@media screen and (max-width: 480px) {
  .text-level-1 {
    font-size: 2rem;
    line-height: 40px !important;
  }

  .text-level-2 {
    font-size: 1.6rem;
    line-height: 30px !important;
  }

  .text-level-3 {
    font-size: 1rem;
    line-height: 20px !important;
  }
}
</style>
