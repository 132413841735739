function page(path) {
  return () =>
    import(/* webpackChunkName: '' */ `@/views/admin/${path}`).then(
      (m) => m.default || m
    );
}

export default [
  {
    path: "/dashboard",
    name: "Dashboard",
    hidden: true,
    component: page("dashboard/Dashboard.vue"),
    meta: {
      title: "Dashboard",
      noCache: true,
    },
  },
  {
    path: "/sign-up",
    name: "SignUp",
    hidden: true,
    component: page("sign-ups/SignUp.vue"),
    meta: {
      title: "SignUp",
      noCache: true,
    },
  },
  {
    path: "/summary",
    name: "Summary",
    hidden: true,
    component: page("summary/Summary.vue"),
    meta: {
      title: "Summary",
      noCache: true,
    },
  },
  {
    path: "/invoice",
    name: "Invoice",
    hidden: true,
    component: page("invoice/Invoice.vue"),
    meta: {
      title: "Invoice",
      noCache: true,
    },
  },
];
