import Vue from "vue";
import qs from "qs";
const datatables = {
  install(Vue) {
    Vue.prototype.$datatables = {
      paramsSerializerDatatables: function (params, options) {
        const { sortBy, sortDesc, page, itemsPerPage } = options;
        params.page = page;
        params.length = itemsPerPage;
        params.start = itemsPerPage * (page - 1);
        params.order = orderDatatables(sortDesc, sortBy, params.columns);

        return {
          params: params,
          paramsSerializer: (params) => {
            return qs.stringify(params);
          },
        };
      },
    };
  },
};
function orderDatatables(sortDesc, sortBy, headers) {
  return [
    {
      column:
        headers.findIndex((column) => column.data === sortBy[0]) === -1
          ? 0
          : headers.findIndex((column) => column.data === sortBy[0]),
      dir: sortDesc[0] ? "desc" : "asc",
    },
  ];
}

Vue.use(datatables);
