var GoogleMapsApiLoader = require("google-maps-api-loader");

GoogleMapsApiLoader({
  libraries: ["places", "geometry", "drawing"],
  apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
}).then(
  function (google) {
    window.google = google;
    /* var autocomplete = new googleApi.maps.places.AutocompleteService(); */
  },
  function (err) {
    console.error(err);
  }
);
