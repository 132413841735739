<template>
  <v-card
    :loading="$store.getters.isLoading"
    :elevation="hover ? 16 : 2"
    :class="{ 'on-hover': hover }"
    rounded="xl"
  >
    <template slot="progress">
      <v-progress-linear height="5" indeterminate style="z-index: 10" />
    </template>
    <v-card-title class="header-container-title" style="z-index: 2">
      <slot name="header" />
    </v-card-title>

    <v-card-text id="scrolling" class="mt-10 px-2 px-md-10">
      <v-container fluid>
        <slot name="body" />
      </v-container>
    </v-card-text>
    <div class="px-7">
      <v-divider></v-divider>
    </div>
    <v-card-actions class="py-1">
      <v-container px-5>
        <v-row
          justify="space-between"
          align-content="center"
          class="fill-height"
        >
          <slot name="actions" />
        </v-row>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "CardModal",
  props: {
    hover: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.$cssEfect.htmlScroll(false);
  },
  beforeDestroy() {
    this.$cssEfect.htmlScroll(true);
  },
};
</script>
<style lang="scss">
.header-container-title {
  background-color: rgba($color: #aaa, $alpha: 0.4);
}
.title-modal-card {
  font-size: 1.8rem !important;
  font-weight: 800 !important;
}

@media only screen and (min-width: 320px) and (max-width: 459px) {
  .title-modal-card {
    font-size: 1.2rem !important;
  }
}
</style>
<style lang="scss">
.card-header-title {
  background-color: rgba($color: #aaa, $alpha: 0.5);
}
</style>
