<template>
  <v-footer padless color="black" height="500">
    <v-container fluid fill-height class="border_blue">
      <v-row
        class="border_pink fill-height"
        align-content="center"
        justify="center"
      >
        <v-col class="text-center border_green my-5" cols="12">
          <span class="title" v-text="'Orderapp'" />
        </v-col>
        <v-col class="text-center border_green my-5" cols="12" md="8" lg="5">
          <span class="text" v-text="$t('general.footer')" />
        </v-col>
        <v-col class="border_green my-5" cols="12">
          <v-row no-gutters justify="center">
            <a
              href="https://apps.apple.com/dk/app/orderapp-partner/id1611525137"
              target="_blank"
            >
              <v-img
                :src="require('@/assets/img/logos/apple-app-store.png')"
                contain
                width="150px"
                style="z-index: 1; image-rendering: optimizeSpeed"
                :title="'apple-app-store'"
                class="apple-img"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular indeterminate color="primary gray" />
                  </v-row>
                </template>
              </v-img>
            </a>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "FooterDefault",
};
</script>
<style lang="scss" scoped>
.title {
  font-size: 3rem !important;
  color: #fff !important;
}
.text {
  color: white !important;
}
.apple-img {
  border: solid 0.5px rgba(193, 193, 193, 0.6) !important;
  border-radius: 5px;
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .text {
    font-size: 0.7rem !important;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .text {
    font-size: 0.8rem !important;
  }
}
</style>
