import Vue from "vue";

const random = {
  install(Vue) {
    Vue.prototype.$appRandom = {
      imageName: function () {
        var text = "";
        var possible =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (var i = 0; i < 20; i++)
          text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
      },
    };
  },
};
Vue.use(random);
