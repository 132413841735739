import Vue from "vue";
import VueMoment from "vue-moment";
import moment from "moment-timezone";
import store from "@/store";

moment.tz.setDefault("Europe/Copenhagen");
moment.locale("en");

Vue.use(VueMoment, {
  moment,
});

export async function loadLangMoment(locale) {
  moment.locale(locale);
}

(async function () {
  await loadLangMoment(store.getters["lang/locale"]);
})();

/* export default moment; */
