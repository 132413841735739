function page(path) {
  return () =>
    import(/* webpackChunkName: '' */ `@/views/front/${path}`).then(
      (m) => m.default || m
    );
}

export default [
  {
    path: "/redirect",
    hidden: true,
    children: [
      {
        path: "/redirect/:path(.*)",
        component: page("redirect/Index.vue"),
      },
    ],
  },
  {
    path: "/",
    name: "Home",
    component: page("homePage/HomePage.vue"),
    hidden: true,
    meta: {
      title: "Home",
      noCache: true,
    },
  },
  {
    path: "/web-ordering",
    name: "WebOrdering",
    component: page("webOrdering/WebOrdering.vue"),
    hidden: true,
    meta: {
      title: "Web Ordering",
      noCache: true,
    },
  },
  {
    path: "/app-ordering",
    name: "AppOrdering",
    component: page("appOrdering/AppOrdering.vue"),
    hidden: true,
    meta: {
      title: "App Ordering",
      noCache: true,
    },
  },
  {
    path: "/self-checkout",
    name: "SelfCheckout",
    component: page("selfCheckout/SelfCheckout.vue"),
    hidden: true,
    meta: {
      title: "Self checkout",
      noCache: true,
    },
  },
  {
    path: "/table-ordering",
    name: "TableOrdering",
    component: page("tableOrdering/TableOrdering.vue"),
    hidden: true,
    meta: {
      title: "Table Ordering",
      noCache: true,
    },
  },
  {
    path: "/delivery",
    name: "DeliveryPage",
    component: page("deliveryPage/DeliveryPage.vue"),
    hidden: true,
    meta: {
      title: "Delivery page",
      noCache: true,
    },
  },
  {
    path: "/marketing",
    name: "Marketing",
    component: page("marketing/Marketing.vue"),
    hidden: true,
    meta: {
      title: "Marketing & automations",
      noCache: true,
    },
  },
  {
    path: "/about-us",
    name: "AboutUs",
    component: page("aboutUs/AboutUs.vue"),
    hidden: true,
    meta: {
      title: "About us",
      noCache: true,
    },
  },
  {
    path: "/terms-of-use",
    name: "TermsOfUse",
    component: page("privacyPolicy/PrivacyPolicy.vue"),
    hidden: true,
    meta: {
      title: "Terms of Use",
      noCache: true,
    },
  },
  {
    path: "/get-started",
    name: "GetStarted",
    component: page("getStarted/GetStarted.vue"),
    hidden: true,
    meta: {
      title: "Get Started",
      noCache: true,
    },
  },
  {
    path: "/go_login",
    name: "Login",
    component: page("auth/Login.vue"),
    hidden: true,
    meta: {
      title: "Login",
      noCache: true,
    },
  },
];
