<template>
  <v-app id="app">
    <NewUpdates />
    <component :is="layout" v-if="layout" />
  </v-app>
</template>

<script>
import Admin from "@/layouts/admin/Index.vue";
import Public from "@/layouts/public/Index.vue";
import Home from "@/layouts/home/Index.vue";
import HomeLayout from "@/layouts/homeLayout/index.vue";

export default {
  el: "#app",
  data: () => ({
    layout: null,
  }),
  methods: {
    setLayout(layout) {
      switch (layout) {
        case "HomeLayout":
          this.layout = HomeLayout;
          break;
        case "Home":
          this.layout = Home;
          break;
        case "Public":
          this.layout = Public;
          break;
        default:
          this.layout = Admin;
          break;
      }
      window.scrollTo(0, 0);
    },
  },
};
</script>
