<template>
  <v-responsive>
    <v-footer padless width="100%" color="#131313">
      <v-row
        class="container-layout mt-16"
        justify="center"
        style="display: flex; position: relative"
      >
        <!-- Contact Details Column -->
        <v-col cols="12" md="3">
          <div class="footer-section">
            <HeaderBlock text-color="#FFFFFF" level="4" text="Orderapp" />
            <div class="pt-5">
              <TextBlock
                level="5"
                text-color="#FFFFFF"
                :text="$t('footer.description')"
              />
            </div>
            <div class="mt-10">
              <Language />
            </div>
            <div class="mt-8 pa-2">
              <!--              <v-row>-->
              <!--                <v-icon color="#FFF" style="margin-right: 10px"-->
              <!--                  >mdi-phone</v-icon-->
              <!--                >-->
              <!--                <TextBlock-->
              <!--                  level="3"-->
              <!--                  text-color="#FFFFFF"-->
              <!--                  text="(+45) 93 40 90 26"-->
              <!--                />-->
              <!--              </v-row>-->
              <v-row class="mt-0">
                <v-icon color="#FFF" style="margin-right: 10px"
                  >mdi-email</v-icon
                >
                <TextBlock
                  level="3"
                  text-color="#FFFFFF"
                  text="hello@orderapp.dk"
                />
              </v-row>
            </div>
          </div>
        </v-col>

        <!-- Page Links Columns -->
        <v-col cols="12" md="3">
          <div class="footer-section">
            <div>
              <HeaderBlock
                text-color="#FFFFFF"
                align="left"
                level="3"
                :text="$t('footer.products')"
              />
              <div
                class="mt-5"
                style="cursor: pointer"
                @click="goToWebOrdering()"
              >
                <TextBlock
                  text-color="#FFFFFF"
                  align="left"
                  level="3"
                  :text="$t('footer.webSiteOrder')"
                />
              </div>
              <div
                class="mt-5"
                style="cursor: pointer"
                @click="goToAppOrdering()"
              >
                <TextBlock
                  text-color="#FFFFFF"
                  align="left"
                  level="3"
                  :text="$t('footer.appOrder')"
                />
              </div>
              <div
                class="mt-5"
                style="cursor: pointer"
                @click="goToSelfCheckout()"
              >
                <TextBlock
                  text-color="#FFFFFF"
                  align="left"
                  level="3"
                  :text="$t('footer.selfCheckout')"
                />
              </div>
              <div
                class="mt-5"
                style="cursor: pointer"
                @click="goToTableOrdering()"
              >
                <TextBlock
                  text-color="#FFFFFF"
                  align="left"
                  level="3"
                  :text="$t('footer.tableOrder')"
                />
              </div>
              <div class="mt-5" style="cursor: pointer" @click="goToDelivery()">
                <TextBlock
                  text-color="#FFFFFF"
                  align="left"
                  level="3"
                  :text="$t('footer.delivery')"
                />
              </div>
              <div
                class="mt-5"
                style="cursor: pointer"
                @click="goToMarketing()"
              >
                <TextBlock
                  text-color="#FFFFFF"
                  align="left"
                  level="3"
                  :text="$t('footer.marketing')"
                />
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="3">
          <div class="footer-section">
            <HeaderBlock
              text-color="#FFFFFF"
              align="left"
              level="3"
              :text="$t('footer.company')"
            />
            <div class="mt-5" style="cursor: pointer" @click="goToAboutUs()">
              <TextBlock
                text-color="#FFFFFF"
                align="left"
                level="3"
                :text="$t('footer.aboutUs')"
              />
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="3">
          <div class="footer-section">
            <HeaderBlock
              text-color="#FFFFFF"
              align="left"
              level="3"
              :text="$t('footer.legal')"
            />
            <div
              class="mt-5"
              style="cursor: pointer"
              @click="goToPrivacyPolicy()"
            >
              <TextBlock
                text-color="#FFFFFF"
                align="left"
                level="3"
                :text="$t('footer.termsOfService')"
              />
            </div>
            <!--            <div-->
            <!--              class="mt-5"-->
            <!--              style="cursor: pointer"-->
            <!--              @click="goToPrivacyPolicy()"-->
            <!--            >-->
            <!--              <TextBlock-->
            <!--                text-color="#FFFFFF"-->
            <!--                align="left"-->
            <!--                level="3"-->
            <!--                :text="$t('footer.privacyPolicy')"-->
            <!--              />-->
            <!--            </div>-->
          </div>
        </v-col>
        <div v-if="$vuetify.breakpoint.mdAndUp" class="divider"></div>
        <div v-if="$vuetify.breakpoint.mdAndUp" class="copyright">
          <p>&copy; 2023 Orderapp. {{ $t("footer.copyRightText") }}</p>
        </div>
        <div v-if="$vuetify.breakpoint.smAndDown" class="divider-x mt-10"></div>
        <div
          v-if="$vuetify.breakpoint.smAndDown"
          class="copyright-x mt-5 pb-10"
        >
          <p>&copy; 2023 Orderapp. All Rights Reserved.</p>
        </div>
      </v-row>
    </v-footer>
  </v-responsive>
</template>

<script>
import HeaderBlock from "@/components/headerBlock/HeaderBlock";
import TextBlock from "@/components/textBlock/TextBlock";
import Language from "@/components/Language";
export default {
  name: "PrimaryFooter",
  components: { Language, TextBlock, HeaderBlock },
  methods: {
    goToWebOrdering() {
      this.$router.push({ path: "/web-ordering" });
    },
    goToAppOrdering() {
      this.$router.push({ path: "/app-ordering" });
    },
    goToSelfCheckout() {
      this.$router.push({ path: "/self-checkout" });
    },
    goToTableOrdering() {
      this.$router.push({ path: "/table-ordering" });
    },
    goToDelivery() {
      this.$router.push({ path: "/delivery" });
    },
    goToMarketing() {
      this.$router.push({ path: "/marketing" });
    },
    goToAboutUs() {
      this.$router.push({ path: "/about-us" });
    },
    goToPrivacyPolicy() {
      this.$router.push({ path: "/terms-of-use" });
    },
  },
};
</script>

<style scoped>
.container-layout {
  min-height: 60vh;
  color: #ffffff;
}
.divider {
  position: absolute;
  bottom: 10vh;
  height: 3px;
  width: 100%;
  background-color: #ffffff;
}
.divider-x {
  height: 3px;
  width: 100%;
  background-color: #ffffff;
}

/* Copyright Text Styling */
.copyright {
  position: absolute;
  left: 0;
  bottom: 2vh;
  color: #ffffff;
}
.copyright-x {
  color: #ffffff;
}
</style>
