import Vue from "vue";

const cssEfects = {
  install(Vue) {
    Vue.prototype.$cssEfect = {
      htmlScroll: function (val) {
        if (val) {
          document.getElementsByTagName("html")[0].style.overflow = "scroll";
        } else {
          document.getElementsByTagName("html")[0].style.overflow = "hidden";
        }
      },
    };
  },
};
Vue.use(cssEfects);
