/* import variables from '@/styles/element-variables.scss' */
import Cookies from "js-cookie";

export const state = {
  /* theme: variables.theme, */
  tagsView:
    Cookies.get("tagsView") === undefined
      ? true
      : JSON.parse(Cookies.get("tagsView")),
  darkTheme:
    Cookies.get("darkTheme") === undefined
      ? false
      : JSON.parse(Cookies.get("darkTheme")),
  drawer:
    Cookies.get("drawer") === undefined
      ? false
      : JSON.parse(Cookies.get("drawer")),
  mini:
    Cookies.get("mini") === undefined ? false : JSON.parse(Cookies.get("mini")),
  btn_interest_color: "fourth",
  main_menu_modify: false,
};

export const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    // eslint-disable-next-line no-prototype-builtins
    if (state.hasOwnProperty(key)) {
      state[key] = value;
      Cookies.set(key, value);
    }
  },
  SET_BTN_INTEREST_COLOR: (state, color) => {
    state.btn_interest_color = color;
  },
  SET_MAIN_MENU_MODIFY: (state, status) => {
    state.main_menu_modify = status;
  },
};

export const actions = {
  changeSetting({ commit }, data) {
    commit("CHANGE_SETTING", data);
  },
  change_btn_interestcolor({ commit }, data) {
    commit("SET_BTN_INTEREST_COLOR", data);
  },
  change_main_menu_modify({ commit }, data) {
    commit("SET_MAIN_MENU_MODIFY", data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
