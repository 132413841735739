<template>
  <span :class="headerClass" :style="{ color: textColor, textAlign: align }">{{
    text
  }}</span>
</template>

<script>
export default {
  name: "HeaderBlock",
  props: {
    level: {
      type: String,
      required: true,
    },
    textColor: {
      type: String,
      default: "#131313",
    },
    align: {
      type: String,
      default: "center",
    },
    text: {
      type: String,
      default: "Default Header Text",
    },
  },
  computed: {
    headerClass() {
      return `header-text-level-${this.level}`;
    },
  },
};
</script>

<style scoped>
.header-text-level-h1 {
  font-size: 4.5rem;
  font-family: "SourceSansPro-Black", sans-serif;
  font-weight: 900;
  line-height: 92px !important;
  text-align: center;
}
.header-text-level-1 {
  font-size: 4.5rem;
  font-family: "SourceSansPro-Semibold", sans-serif;
  font-weight: 600;
  line-height: 92px !important;
  text-align: center;
}
.header-text-level-2 {
  font-size: 3rem;
  font-family: "SourceSansPro-Semibold", sans-serif;
  font-weight: 600;
  line-height: 62px !important;
  text-align: center;
}
.header-text-level-3 {
  font-size: 2rem;
  font-family: "SourceSansPro-Bold", sans-serif;
  font-weight: 700;
  line-height: 48px !important;
  text-align: center;
}
.header-text-level-4 {
  font-size: 1.5rem;
  font-family: "SourceSansPro-Regular", sans-serif;
  font-weight: 500;
  line-height: 32px !important;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .header-text-level-h1 {
    font-size: 3rem;
    line-height: 72px !important;
  }
  .header-text-level-1 {
    font-size: 3rem;
    line-height: 72px !important;
  }

  .header-text-level-2 {
    font-size: 2.5rem;
    line-height: 52px !important;
  }

  .header-text-level-3 {
    font-size: 1.5rem;
    line-height: 38px !important;
  }
  .header-text-level-4 {
    font-size: 1.2rem;
    line-height: 28px !important;
  }
}

@media screen and (max-width: 480px) {
  .header-text-level-h1 {
    font-size: 2.5rem;
    line-height: 62px !important;
  }
  .header-text-level-1 {
    font-size: 2.5rem;
    line-height: 62px !important;
  }

  .header-text-level-2 {
    font-size: 2rem;
    line-height: 42px !important;
  }

  .header-text-level-3 {
    font-size: 1.6rem;
    line-height: 30px !important;
  }
  .header-text-level-4 {
    font-size: 1rem;
    line-height: 25px !important;
  }
}
</style>
