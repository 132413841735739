<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    right
    fixed
    temporary
    :width="width"
    color="white"
  >
    <v-app-bar
      absolute
      color="gray"
      elevation="0"
      height="80"
      class="px-5"
      rounded="rounded-t-xl"
    >
      <v-row justify="end">
        <v-btn icon @click.stop="drawer = !drawer">
          <v-icon color="primary" large v-text="'mdi-close'" />
        </v-btn>
      </v-row>
    </v-app-bar>
    <v-container mt-15 pt-10 px-7 class="test_red">
      <v-row no-gutters class="test_blue">
        <v-list nav expand class="test_yellow" width="100%">
          <template v-for="(item, i) in sections">
            <v-list-item
              :key="`subheader-${i}`"
              :to="{ name: item.pathName }"
              active-class="active"
              :exact-path="item.pathName === 'Home' ? true : false"
            >
              <v-list-item-title class="item-title" v-text="$t(item.title)" />
            </v-list-item>
          </template>
          <v-list-item
            v-if="!$store.getters.checkAuth"
            active-class="active"
            @click="showAuth = true"
            @click.stop="drawer = !drawer"
          >
            <v-list-item-title
              class="item-title"
              v-text="$t('general.login')"
            />
          </v-list-item>
          <v-list-item v-else active-class="active" @click.native="logout">
            <v-list-item-title
              class="item-title"
              v-text="$t('general.logout')"
            />
          </v-list-item>
        </v-list>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import sections from "../data/sections";
export default {
  name: "DrawerMenu",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    drawer: false,
    sections: sections,
  }),
  computed: {
    width: function () {
      switch (true) {
        case this.$vuetify.breakpoint.xsOnly:
          return "100%";
        case this.$vuetify.breakpoint.smOnly:
          return "60%";
        case this.$vuetify.breakpoint.mdOnly:
          return "50%";
        case this.$vuetify.breakpoint.lgAndUp:
          return "30%";
        default:
          return "100%";
      }
    },
  },
  watch: {
    show: function () {
      this.drawer = this.show;
    },
    drawer: function () {
      if (!this.drawer) {
        this.$emit("click", false);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.active,
.active .item-title {
  color: var(--v-primary-base) !important;
}
</style>
