<template>
  <v-btn
    color="error"
    block
    :loading="isLoading || $store.getters.isLoading"
    :disabled="!valid"
    rounded
    large
    @click="$emit('click', false)"
  >
    <span class="btn" v-text="$t(title)" />
  </v-btn>
</template>

<script>
export default {
  name: "ButtonCancel",
  props: {
    title: {
      type: String,
      default: "general.cancel",
    },
    valid: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="sss" scoped>
/* .btn {
  text-transform: none;
} */
</style>
