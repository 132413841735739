<template>
  <v-btn
    color="primary"
    class="text-transform"
    href="https://yfa5t36aavt.typeform.com/to/dJG2rcoQ"
    v-text="$t('general.get_in_touch')"
  />
</template>

<script>
export default {
  name: "ButtonContact",
};
</script>
