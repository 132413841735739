import axios from "axios";
import Cookies from "js-cookie";

// state
export const state = {
  user: null,
  token: Cookies.get("token"),
  permissions: [],
  isAdmin: false,
};

export const getters = {
  user: (state) => state.user,
  token: (state) => state.token,
  check: (state) => state.user !== null,
};

// mutations
export const mutations = {
  SET_TOKEN: (state, { token, remember }) => {
    state.token = token;
    Cookies.set("token", token, { expires: remember ? 365 : null });
  },

  FETCH_USER_SUCCESS: (state, { user }) => {
    state.user = user;
    state.isAdmin = user.role === "Admin";
  },

  FETCH_USER_FAILURE: (state) => {
    state.token = null;
    Cookies.remove("token");
  },

  LOGOUT: (state) => {
    state.token = null;
    state.user = null;
    state.isAdmin = false;
    Cookies.remove("token");
  },
};

// actions
export const actions = {
  setToken({ commit }, payload) {
    commit("SET_TOKEN", payload);
  },

  async fetchUser({ commit }) {
    try {
      const { data } = await axios.get("/Api/V1/user/profiles");
      commit("FETCH_USER_SUCCESS", { user: data.data });
    } catch (e) {
      commit("FETCH_USER_FAILURE");
    }
  },

  async logout({ commit }) {
    try {
      await axios.post("/logout");
      commit("LOGOUT");
    } catch (e) {
      console.log(e);
    }

    commit("LOGOUT");
  },
};
