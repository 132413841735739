<template>
  <v-tabs
    id="tab-menus"
    center-active
    class="tabs-container"
    :hide-slider="shouldHideSlider"
  >
    <v-tab
      v-for="(item, index) in sections"
      :key="index"
      :to="{ name: item.pathName }"
    >
      <span
        v-if="item.title !== 'Summary'"
        class="font_tabs"
        v-text="$t(item.title)"
      />
    </v-tab>
    <v-menu
      v-model="menuOpen"
      :close-on-content-click="false"
      :offset-y="true"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          variant="plain"
          style="box-shadow: none; text-transform: capitalize; margin-top: 6px"
          v-on="on"
        >
          Summaries
          <v-icon dark>mdi-menu-down</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="(type, index) in types"
          :key="index"
          @click="handleTypeChange(type)"
        >
          <v-list-item-title v-text="type"></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-tabs>
</template>

<script>
import sections from "../data/sections";
export default {
  name: "TabMenu",
  data: () => ({
    sections: sections,
    types: ["Orders", "Customers"],
    selectedType: "Orders",
    menuOpen: false,
  }),
  computed: {
    shouldHideSlider() {
      return this.$route.name === "Summary";
    },
  },
  watch: {
    // Watch for changes to the 'type' query parameter in the route
    "$route.query.type": function (newType) {
      if (newType && this.types.includes(newType)) {
        this.selectedType = newType;
      }
    },
  },
  methods: {
    handleTypeChange(newType) {
      this.selectedType = newType;
      this.menuOpen = false;
      this.$router.push({
        name: "Summary",
        query: { type: newType },
      });
    },
  },
};
</script>
<style lang="scss">
.tabs-container {
  border: solid;
  border-color: rgba($color: #aaa, $alpha: 0.5);
  border-width: 0.2px;
}

.v-tab {
  text-transform: none !important;
}

.v-tab:hover {
  opacity: 0.7;
}
</style>
