<template>
  <v-menu rounded offset-y bottom :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-badge :content="unreadCount" :color="color" overlap>
          <v-tooltip bottom color="#000">
            <template v-slot:activator="{ on2, attrs2 }">
              <v-icon
                v-bind="attrs2"
                v-on="on2"
                v-text="'mdi-bell-ring-outline'"
              />
            </template>
            <span v-text="'Notifications'" />
          </v-tooltip>
        </v-badge>
      </v-btn>
    </template>

    <v-card v-if="isReady" class="mx-auto" width="500" color="rgb(0,0,0,.6)">
      <v-list color="#ff3e00">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title
              class="text-uppercase cursor_pointer"
              @click="type == 'unread' ? (type = 'read') : (type = 'unread')"
              v-text="Change"
            />
          </v-list-item-content>
          <v-list-item-action>
            <v-tooltip bottom color="transparent">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click.prevent="markAll(type == 'unread' ? true : null)"
                >
                  <v-icon>
                    {{
                      type == "unread" ? "mdi-email-open" : "mdi-email-outline"
                    }}
                  </v-icon>
                </v-btn>
              </template>
              <span>
                {{ type == "unread" ? "mark_all_read" : "mark_all_unread" }}
              </span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-divider />

      <v-list v-if="notifications.length > 0" one-line color="transparent">
        <v-list-item-group color="#88138d" active-class="color_1--text">
          <v-list-item v-for="(item, i) in notifications[type]" :key="i">
            <v-list-item-content
              @click="showNotification(item.notification[0])"
            >
              <v-list-item-title v-text="item.notification[0].title" />
              <v-list-item-subtitle v-text="item.notification[0].content" />
            </v-list-item-content>

            <v-list-item-action>
              <v-list-item-action-text
                v-text="differentTime(item.created_at)"
              />
              <v-tooltip bottom color="transparent">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click.prevent="mark(item, type === 'unread' ? true : null)"
                  >
                    <v-icon>
                      {{
                        type == "unread"
                          ? "mdi-email-outline"
                          : "mdi-email-open"
                      }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>
                  {{ type == "unread" ? "mark_read" : "mark_unread" }}
                </span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-list v-else>
        <v-container fluid fill-height>
          <v-row class="fill-height" align-content="center" justify="center">
            <v-col class="text-center font_size_regular">
              {{ "all_done" }}
            </v-col>
          </v-row>
        </v-container>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "Notification",
  props: {},
  data: () => ({
    isReady: true,
    now: undefined,
    type: "unread",
    unreadCount: 5,
    notifications: [
      {
        text: "holaaa",
        type: "unread",
      },
    ],
    sound: "/sounds/notification.mp3",
  }),
  computed: {
    color() {
      return this.unreadCount == 0
        ? "transparent"
        : this.unreadCount > 0
        ? "#ff3e00"
        : "#88138d";
    },
    /* Change() {
      let text = this.type == "unread" ? "read" : "unread";
      return "notifications.view_";
    }, */
  },

  /* methods: {
    playSound() {
      let alert = new Audio(this.sound);
      alert.play();
    },
    async getNotification() {
      const self = this;
      let params = {
        paginate: "no",
      };
      const { data } = await axios.get("/api/notificationcustomer", {
        params: params,
      });
      self.notifications = data;
      self.unreadCount = data.unread.length;

      if (self.unreadCount > 0) {
        self.playSound();
      }
      self.isReady = true;
    },
    async mark(value, status) {
      let form = new Form({
        read_at: status,
      });

      const { data } = await form.put("/api/notificationcustomer/" + value.id);
      this.getNotification();
    },
    async markAll(status) {
      let form = new Form({
        read_at: status,
      });

      const { data } = await form.put("/api/notificationcustomer/" + "All");
      this.getNotification();
    },
    differentTime(time) {
      var now = this.$moment();
      var diff = [];
      diff["years"] = now.diff(time, "years");
      diff["months"] = now.diff(time, "months");
      diff["days"] = now.diff(time, "days");
      diff["hours"] = now.diff(time, "hours");
      diff["minutes"] = now.diff(time, "minutes");

      switch (true) {
        case diff.years > 0:
          return diff.years + " Years";
        case diff.months > 0:
          return diff.months + " Months";
        case diff.days > 0:
          return diff.days + " days";
        case diff.hours > 0:
          return diff.hours + " hours";
        case diff.minutes > 0:
          return diff.minutes + " minutes";
        default:
          return diff.minutes + " minutes";
          break;
      }
    },
    showNotification(value) {
      Swal.fire({
        title: value.title,
        text: value.content,
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      });
    },
  }, */
};
</script>
