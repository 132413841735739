<template>
  <v-app>
    <v-main>
      <transition name="fade-transform" mode="out-in">
        <router-view />
      </transition>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "Public",
};
</script>
