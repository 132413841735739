import Vue from "vue";
import moment from "moment-timezone";
import i18n from "@/plugins/i18n.js";
const formatters = {
  install(Vue) {
    Vue.prototype.$appFormatters = {
      formatDateDB: function (dateString) {
        return moment(dateString).format("YYYY-MM-DD");
      },
      formatDateRange: function (dateString) {
        return moment(dateString).format("DD-MM-YYYY");
      },
      formatDateRead: function (dateString, format) {
        return moment(dateString).format(format ? format : "MMMM DD, YYYY");
      },
      formatDateDay: function (dateString) {
        return moment(dateString).format("DD");
      },
      formatDateMonthName: function (dateString) {
        return moment(dateString).format("MMM");
      },
      dateToNow: function (date) {
        return moment(date).fromNow();
      },
      formatTime: function (value) {
        return moment(value, ["HH:mm"]).format("h:mm A");
      },
      formatMoney(amount, decimalCount = 2, decimal = ",", thousands = ".") {
        try {
          decimalCount = Math.abs(decimalCount);
          decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

          const negativeSign = amount < 0 ? "-" : "";

          let i = parseInt(
            (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
          ).toString();
          let j = i.length > 3 ? i.length % 3 : 0;

          return (
            negativeSign +
            (j ? i.substr(0, j) + thousands : "") +
            i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
            (decimalCount
              ? decimal +
                Math.abs(amount - i)
                  .toFixed(decimalCount)
                  .slice(2) +
                " " +
                "Dkk"
              : "")
          );
        } catch (e) {
          console.log(e);
        }
      },
      formatMoneyWithoutDecimal(
        amount,
        decimalCount = 0,
        decimal = ".",
        thousands = ","
      ) {
        try {
          decimalCount = Math.abs(decimalCount);
          decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

          const negativeSign = amount < 0 ? "-" : "";

          let i = parseInt(
            (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
          ).toString();
          let j = i.length > 3 ? i.length % 3 : 0;

          return (
            negativeSign +
            (j ? i.substr(0, j) + thousands : "") +
            i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
            (decimalCount
              ? decimal +
                Math.abs(amount - i)
                  .toFixed(decimalCount)
                  .slice(2)
              : "")
          );
        } catch (e) {
          console.log(e);
        }
      },
      formatTimeEu: function (value) {
        return moment(value, ["HH:mm"]).format("HH:mm");
      },
      formatTimeEuGetDatetime: function (value) {
        if (value) {
          let hour = value.split(" ");
          return moment(hour[1], ["HH:mm"]).format("HH:mm");
        } else {
          return i18n.t("checkout.no_etimated");
        }
      },
      formatTimeEuGetTimeExtra: function (value, extra) {
        let newValue = moment(value).add(extra, "m").toDate();
        if (newValue) {
          if (newValue.getMinutes() < 10) {
            return newValue.getHours() + ":0" + newValue.getMinutes();
          } else {
            return newValue.getHours() + ":" + newValue.getMinutes();
          }
        } else {
          return i18n.t("checkout.no_etimated");
        }
      },
    };
  },
};
Vue.use(formatters);
