<template>
  <router-link to="/">
    <v-img
      :src="logo"
      height="auto"
      contain
      position="left"
      :max-width="$vuetify.breakpoint.mdAndUp ? '150' : '100px'"
      style="image-rendering: optimizeSpeed"
      :title="$t('general.go_to_home')"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular indeterminate color="primary gray" />
        </v-row>
      </template>
    </v-img>
  </router-link>
</template>

<script>
export default {
  name: "Logo",
  data: () => ({
    logo: require("@/assets/img/logos/logo_white.png"),
  }),
};
</script>
