import Vue from "vue";
import Vuetify from "vuetify";
import en from "vuetify/es5/locale/en";
import de from "vuetify/es5/locale/de";
import dk from "@/utils/vuetify-dk";
import es from "vuetify/es5/locale/es";
import store from "@/store";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

const opts = {
  theme: {
    dark: false,
    default: "light",
    disable: false,
    options: {
      cspNonce: "dQw4w9WgXcQ",
      minifyTheme: undefined,
      themeCache: undefined,
      variations: undefined,
      customProperties: true,
    },
    themes: {
      dark: {
        primary: "#0079de",
        secondary: "#2d1582",
        third: "#fcb33d",
        fourth: "#662d91",
        five: "#636e77",
        success: "#4caf50",
        error: "#ff5252",
      },
      light: {
        primary: "#0079de",
        secondary: "#2d1582",
        third: "#fcb33d",
        fourth: "#662d91",
        five: "#636e77",
        success: "#4caf50",
        error: "#ff5252",
      },
    },
    icons: {
      /* iconfont: "md", */
      iconfont: "mdi",
      /* iconfont: "fa", */
    },
  },
  customProperties: true,

  lang: {
    locales: { en, dk, de, es },
    current: "en",
  },
};

export async function loadLangVuetify(locale) {
  opts.lang.current = locale;
}

(async function () {
  await loadLangVuetify(store.getters["lang/locale"]);
})();

export default new Vuetify(opts);
