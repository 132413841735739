import axios from "axios";
import store from "@/store";
import router from "@/router";

window.axios = axios;

if (process.env.NODE_ENV == "development") {
  axios.defaults.baseURL = "https://dksnap.com";
}

// Request interceptor
axios.interceptors.request.use((request) => {
  const token = store.getters.token;
  if (token) {
    request.headers.common.Authorization = `Bearer ${token}`;
  }

  return request;
});

// Response interceptor
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response;

    if (status >= 500) {
      Swal.fire({
        icon: "error",
        title: "error_alert_title",
        text: "error_alert_text",
        reverseButtons: true,
        confirmButtonText: "ok",
      });
    }

    /**
     * @status 401 => LOGIN BAD CREDENTIALS
     * @status 403 => PERMITIONS
     * @status 404 => SOME DATE NOT EXIST
     * @status 405 => VALIDATION ERROR
     * @status 422 => ERROR REQUEST
     * @status 429 => TOO ATTEMPS LOGIN
     *
     */
    var DefaultStatus = [400, 429, 404];

    if (DefaultStatus.includes(status)) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response.data.message.note,
        reverseButtons: true,
        confirmButtonText: "ok",
      });
    }

    if (status === 401) {
      Swal.fire({
        icon: "error",
        title: "Bad Login",
        text: error.response.data.message,
        reverseButtons: true,
        confirmButtonText: "ok",
      }).then(() => {
        // store.commit("auth/LOGOUT");
      });
    }

    if (status === 403) {
      router.push({ name: "Dashboard" });
      Swal.fire({
        icon: "error",
        title: "error_alert_title",
        text: error.response.data.errors.message,
        reverseButtons: true,
        confirmButtonText: "ok",
      });
    }
    if (status === 405) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response.data.message,
        reverseButtons: true,
        confirmButtonText: "ok",
      });
    }
    //SESIONID EXPIRED
    if (status === 410) {
      store.dispatch("guest/removeSessionId");
      setTimeout(() => {
        store.dispatch("cart/setLoad");
      }, 200);
    }

    if (status === 422) {
      var str = "";
      for (var p in error.response.data.errors) {
        if (
          Object.prototype.hasOwnProperty.call(error.response.data.errors, p)
        ) {
          str += p + ":" + error.response.data.errors[p] + "<br>";
        }
      }
      Swal.fire({
        icon: "error",
        title: error.response.data.message,
        html: str,
        reverseButtons: true,
        confirmButtonText: "ok",
      });
    }

    //Finish the loading
    //router.app.$loading.finish();
    store.dispatch("loading/done");
    return Promise.reject(error);
  }
);
export default axios;
