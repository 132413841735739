// states
export const state = {
  info: null,
};

// mutations
export const mutations = {
  SET_APP_INFO: (state, data) => {
    state.info = data;
  },
};

// actions
export const actions = {
  setAppInfo({ commit }, payload) {
    commit("SET_APP_INFO", payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
