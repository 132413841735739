import Cookies from "js-cookie";
import defaultSettings from "@/services/settings";

const { locale, locales } = defaultSettings;

// state
export const state = {
  locale: Cookies.get("locale") === undefined ? locale : Cookies.get("locale"),
  locales: locales,
};

// getters
export const getters = {
  locale: (state) => state.locale,
  locales: (state) => state.locales,
};

// mutations
export const mutations = {
  SET_LOCALE: (state, { locale }) => {
    state.locale = locale;
    Cookies.set("locale", locale, { expires: 365 });
  },
};

// actions
export const actions = {
  setLocale({ commit }, { locale }) {
    commit("SET_LOCALE", { locale });
  },
};
