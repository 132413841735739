<template>
  <v-app>
    <MenuHeaderPublic :sections="sections" />
    <v-main class="border_green">
      <transition name="fade-transform" mode="out-in">
        <router-view />
      </transition>
    </v-main>
    <!--    <FooterDefault />-->
  </v-app>
</template>

<script>
import { Sections } from "./data/sections";
export default {
  name: "Home",
  data: () => ({
    sections: Sections,
  }),
};
</script>
