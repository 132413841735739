import Vue from "vue";
// Components that are registered globaly.
import Language from "./Language";
import MenuHeaderPublic from "./menu-headers/MenuHeaderPublic";
import CardModal from "./modal/CardModal";
import CardDefault from "./cards/CardDefault";
import Logo from "./Logo";
import ButtonSuccess from "./buttons/ButtonSuccess";
import ButtonCancel from "./buttons/ButtonCancel";
import ButtonOther from "./buttons/ButtonOther";
import ButtonContact from "./buttons/ButtonContact";
import NewUpdates from "./notifications/NewUpdates";
import FooterDefault from "./footers/FooterDefault";

[
  Language,
  MenuHeaderPublic,
  CardModal,
  CardDefault,
  Logo,
  ButtonSuccess,
  ButtonCancel,
  ButtonOther,
  NewUpdates,
  FooterDefault,
  ButtonContact,
].forEach((Component) => {
  Vue.component(Component.name, Component);
});
