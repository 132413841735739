<template>
  <v-app>
    <header>
      <NavBar />
    </header>
    <v-main style="margin-top: 64px">
      <transition name="fade-transform" mode="out-in">
        <router-view />
      </transition>
    </v-main>
    <PrimaryFooter />
  </v-app>
</template>

<script>
import NavBar from "@/components/menu-headers/navbar/NavBar";
import PrimaryFooter from "@/components/footers/PrimaryFooter";
export default {
  name: "HomeLayout",
  components: { PrimaryFooter, NavBar },
};
</script>

<style scoped>
header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; /* Ensure the navbar is above other content */
  background-color: white; /* Set the background color of the navbar */
}
</style>
