const getters = {
  appInfo: (state) => state.app.info,
  checkAuth: (state) => state.auth.user !== null,
  isLoading: (state) => state.loading.isLoading,
  locale: (state) => state.lang.locale,
  locales: (state) => state.lang.locales,
  token: (state) => state.auth.token,
  user: (state) => state.auth.user,
};
export default getters;
