import Vue from "vue";
import App from "./App";
import router from "@/router";
import store from "@/store";
import vuetify from "@/plugins/vuetify";
import i18n from "@/plugins/i18n";
import "./registerServiceWorker";

import "@/plugins";
import "@/utils";
import "@/validators";
import "@/components";
import "@/scss/main.scss";

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  vuetify,
  ...App,
});
