<template>
  <v-menu rounded offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        small
        class="px-5"
        style="color: white"
        v-on="on"
      >
        <v-icon :class="'flag_' + locale" v-bind="attrs" v-on="on" />
      </v-btn>
    </template>

    <v-list color="white">
      <v-list-item
        v-for="(value, key) in locales"
        :key="key"
        class="navbar_link_content"
        @click.prevent="setLocale(value)"
      >
        <v-btn icon>
          <v-icon :class="'flag_' + value" />
        </v-btn>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";
import { loadMessages } from "@/plugins/i18n";
import { loadLangMoment } from "@/plugins/moment";

export default {
  name: "Language",
  computed: {
    ...mapGetters({
      locale: "lang/locale",
      locales: "lang/locales",
    }),
  },
  methods: {
    setLocale: function (locale) {
      if (this.$i18n.locale !== locale) {
        loadMessages(locale);
        loadLangMoment(locale);
        this.$vuetify.lang.current = locale;
        this.$store.dispatch("lang/setLocale", { locale });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.flag_en {
  background: url("../assets/img/flags/united-kingdom.svg") no-repeat center
    center / cover;
}
.flag_de {
  background: url("../assets/img/flags/germany.svg") no-repeat center center /
    cover;
}
.flag_dk {
  background: url("../assets/img/flags/denmark.svg") no-repeat center center /
    cover;
}
</style>
