import Vue from "vue";
import store from "@/store";

const currency = {
  install(Vue) {
    Vue.prototype.$appCurrency = {
      configInput: function () {
        return {
          decimal: ",",
          thousands: ".",
          prefix: "",
          suffix: store.getters.storeInfo.currency,
          precision: 2,
          masked: false,
          allowBlank: true,
          min: Number.MIN_SAFE_INTEGER,
          max: Number.MAX_SAFE_INTEGER,
        };
      },
    };
  },
};
Vue.use(currency);
