import Vue from "vue";
import i18n from "@/plugins/i18n.js";
const inputsRules = {
  install(Vue) {
    Vue.prototype.$rules = {
      required: (v) => !!v || i18n.t("inputs.required"),
      phoneNumber: (v) =>
        (v && v.length == 15) || i18n.t("inputs.invalid_phone_number"),
      minPassword: (v) => (v && v.length >= 8) || i18n.t("inputs.min_password"),
      creditCard: (v) =>
        (v && v.length == 19) || i18n.t("inputs.invalid_credit_card"),
      email: (v) =>
        !v ||
        //eslint-disable-next-line
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        i18n.t("inputs.invalid_email"),
      cvv: (v) => (v && v.length === 3) || i18n.t("inputs.invalid_cvv"),
      domain: (v) =>
        !v ||
        //eslint-disable-next-line
        /([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/.test(
          v
        ) ||
        i18n.t("inputs.invalid_domain"),
    };
  },
};
Vue.use(inputsRules);
