<template>
  <v-card
    :loading="$store.getters.isLoading"
    :elevation="hover ? 16 : 2"
    :class="{ 'on-hover': hover }"
    rounded="xl"
  >
    <template slot="progress">
      <v-progress-linear height="5" indeterminate style="z-index: 10" />
    </template>
    <v-card-title style="z-index: 2" class="card-header-title">
      <slot name="header" />
    </v-card-title>

    <v-card-text id="scrolling" class="px-2 px-md-5">
      <v-container fluid>
        <slot name="body" />
      </v-container>
    </v-card-text>
    <div class="px-7">
      <v-divider></v-divider>
    </div>
    <v-card-actions class="py-1">
      <v-container px-5>
        <v-row
          justify="space-between"
          align-content="center"
          class="fill-height"
        >
          <slot name="actions" />
        </v-row>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "CardDefault",
  props: {
    hover: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
.title-modal-card {
  font-size: 1.8rem !important;
  font-weight: 800 !important;
}

@media only screen and (min-width: 320px) and (max-width: 459px) {
  .title-modal-card {
    font-size: 1.2rem !important;
  }
}
</style>
<style lang="scss">
.card-header-title {
  height: 85px;
  background-color: rgba($color: #aaa, $alpha: 0.5);
}
</style>
