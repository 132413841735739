<template>
  <v-app>
    <v-app-bar
      id="bar-menu-admin"
      app
      extended
      elevation="0"
      :extension-height="$vuetify.breakpoint.mobile ? 0 : 50"
      hide-on-scroll
      class="test_blue"
      scroll-target="#scrolling-menu"
    >
      <div class="px-2">
        <Logo />
      </div>
      <v-spacer />
      <div class="px-2">
        <v-btn icon @click="handleFullScreen()">
          <v-icon large v-text="'mdi-fullscreen'" />
        </v-btn>
      </div>

      <div class="px-2">
        <Language />
      </div>
      <div class="px-2">
        <profile />
      </div>

      <template v-if="!$vuetify.breakpoint.mobile" v-slot:extension>
        <TabMenu />
      </template>
      <v-app-bar-nav-icon
        v-show="$vuetify.breakpoint.mobile"
        class="primary--text"
        x-large
        @click="drawer = true"
      />
    </v-app-bar>
    <v-main id="scrolling-menu" class="test_purple">
      <transition name="fade-transform" mode="out-in">
        <router-view />
      </transition>
    </v-main>
    <DrawerMenu :show="drawer" @click="drawer = false" />
  </v-app>
</template>

<script>
import { Profile, TabMenu, DrawerMenu } from "./components";
import { toggleFullScreen } from "@/utils/full-screen";
export default {
  middleware: "admin",
  name: "Admin",
  components: {
    Profile,
    TabMenu,
    DrawerMenu,
  },
  data: () => ({
    drawer: false,
  }),
  methods: {
    handleFullScreen() {
      toggleFullScreen();
    },
  },
};
</script>
<style scoped>
.background_template {
  background-color: #f2f3f8;
  height: 100vh;
}
</style>
